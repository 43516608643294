import React from 'react'

const P = ({ className, children, ...props }) => (
  <p
    className={['mb-3', className ? className : ''].join(' ')}
    {...props}
  >
    {children}
  </p>
)
export default P