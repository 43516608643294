import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Columns, Container, Heading, Section } from 'react-bulma-components'
import SingleProductThumbnail from '../components/e-commerce/single-product-thumbnail'
import { useProducts, useProductsRequest } from '../stores/products'
import P from '../components/elements/p'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ProductsPage = () => {
  const [state, actions] = useProducts()
  const { t } = useTranslation()

  const [request] = useProductsRequest({
    key: 'get /products'
  })

  //Fetch products on filter change (get all childrens categories)
  React.useEffect(() => {
    actions.fetch({
      kind: 'attempt',
      limit: 100,
    })
  }, [])


  return (
    <Layout>

      <Section>
        <Container>
          <Columns>
            <Columns.Column>
              <Heading size={4}>{t('products:attempts.title')}</Heading>
              <P>
                {t('products:attempts.description')}
              </P>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column ml={4}>
              <Columns gap={4}>
                {state.all.map(
                  product => (
                    <Columns.Column size={3} key={product._id}>
                      <SingleProductThumbnail product={product} discount={state.prices && state.prices[product._id]}/>
                    </Columns.Column>
                  )
                )}
              </Columns>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`